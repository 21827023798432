import {
	Button,
	Paper,
	FormControl,
	Typography,
	TextField,
	Link,
	Grid,
	useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import React from "react";
import { observer } from "mobx-react";
import { Alert } from "@mui/material";
import { userStore } from "../stores/userStore";
import { User } from "../models/userModel";

export const RegisterPanel = observer(() => {
	const theme = useTheme();
	const [username, setUsername] = React.useState<string>("");
	const [password, setPassword] = React.useState<string>("");
	const [email, setEmail] = React.useState<string>("");
	const [passwordRepeat, setPasswordRepeat] = React.useState<string>("");
	const [showError, setShowError] = React.useState<boolean>(false);

	const navigate = useNavigate();

	const register = async (e: React.MouseEvent<HTMLElement>) => {
		setShowError(false);
		const user: User = { username: username, password: password, email: email };
		await userStore.register(user);
		if (userStore.isError) setShowError(true);
		if (!userStore.isError) navigate("/questions");
	};

	return (
		<Paper>
			<Grid container>
				<Grid item sm={3}></Grid>
				<Grid item xs={12} sm={6}>
					<Typography variant={"h5"} sx={{ padding: theme.spacing(2) }}>
						Create an account
					</Typography>
					{showError ? (
						<Alert severity="error">Error: Something went wrong</Alert>
					) : (
						<React.Fragment />
					)}
					<FormControl fullWidth sx={{ margin: theme.spacing(2, 0) }}>
						<TextField
							placeholder="Username"
							variant="outlined"
							value={username}
							onChange={(e) => setUsername(e.target.value)}
							required
						/>
					</FormControl>
					<FormControl fullWidth sx={{ margin: theme.spacing(2, 0) }}>
						<TextField
							placeholder="Email"
							variant="outlined"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
						/>
					</FormControl>
					<FormControl fullWidth sx={{ margin: theme.spacing(2, 0) }}>
						<TextField
							type={"password"}
							placeholder="Password"
							variant="outlined"
							onChange={(e) => setPassword(e.target.value)}
							value={password}
							required
						/>
					</FormControl>
					<FormControl fullWidth sx={{ margin: theme.spacing(2, 0) }}>
						<TextField
							type={"password"}
							placeholder="Password again"
							variant="outlined"
							onChange={(e) => setPasswordRepeat(e.target.value)}
							value={passwordRepeat}
							required
						/>
					</FormControl>
					<FormControl fullWidth sx={{ margin: theme.spacing(2, 0) }}>
						<Button
							variant="contained"
							color="primary"
							onClick={register}
							disabled={userStore.isLoading}
						>
							Register
						</Button>
					</FormControl>

					<Typography
						sx={{
							textAlign: "center",
							color: theme.palette.text.primary,
							padding: theme.spacing(2),
						}}
					>
						<Link href="/login">Already have an account? Login here</Link>
					</Typography>
				</Grid>
			</Grid>
		</Paper>
	);
});
