import { Editorial, EditorialDetails } from "../models/editorialModel";

import {  Box, Button, Chip, Divider, List, ListItem, ListItemIcon, ListItemText, Stack, styled, Typography } from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import ReactMarkdown from 'react-markdown'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from "react";
import { Link } from "react-router-dom";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export interface EditorialListProps {
  editorials: Editorial[];
  questionId: string;
}


export interface EditorialListItemDescriptionProps {
  editorial: Editorial;
  questionId: string;
}

export interface EditorialListItemProps {
  editorial: Editorial;
  questionId: string;
}

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0, 1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const EditorialListItemDescription = (props: EditorialListItemDescriptionProps) => {
  const { editorial, questionId } = props;

  const tags = editorial.tags.map(tag => <Chip label={tag.label} />);
  return (
    <React.Fragment>
        <Stack direction="row" spacing={[0,2]}>
          <Typography variant="caption"> {editorial.author}</Typography>
          <Typography variant="caption"> {editorial.views} views</Typography>
          <Typography variant="caption">2 months ago</Typography>
        </Stack>
        <Typography variant="body1">
          {editorial.shortDescription}
        </Typography>
        <Stack direction="row" spacing={1}>
          {tags}
        </Stack>

    </React.Fragment>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100%",
      display: "flex",
      flexFlow: "column",
      flex: 1,
    },
    margin: {
      padding: theme.spacing(3, 0, 0, 0),
    },
    paper: {
      flex: "1 1 auto",
    },
    stack: {
      background: "transparent",
    },
    root: {
      a: {
        textDecoration: "none",
      },
      "&:hover": {
        backgroundColor: "#eee",

      }
    }
  }),
);

export const EditorialListItem = (props: EditorialListItemProps) => {
  const { editorial, questionId } = props;
  const classes = useStyles();

  return(
    <React.Fragment>
      <Link to={`/questions/${questionId}/solutions/${editorial.id}`} style={{ textDecoration: 'none' }}>
        <div className={classes.root}>
        <ListItem >
          <ListItemIcon>
            <Stack direction="column" className={classes.stack}>
              <Item><ArrowDropUp /></Item>
              <Item><Typography variant="button">{editorial.votes}</Typography> </Item>
              <Item><ArrowDropDownIcon /></Item>
            </Stack>
          </ListItemIcon>
            <ListItemText primary={editorial.title} secondary={<EditorialListItemDescription questionId={questionId} editorial={editorial} />} />
        </ListItem>
        </div>

      </Link>
      <Divider variant="inset" component="li" />
    </React.Fragment>


  )
}

export const EditorialList = (props: EditorialListProps) => {
  const { editorials, questionId } = props;
  const editorialList = editorials.map(editorial => <EditorialListItem editorial={editorial} questionId={questionId} />);
  return(
    <List sx={{ width: '100%', bgcolor: 'background.paper', textDecoration: 'none' }}>
      {editorialList}
    </List>
  )
};

export interface EditorialProps {
  editorialId?: string;
  questionId: string;
}

export interface EditorialDetailsProps {
  editorialDetails: EditorialDetails;
  questionId: string;
}

export const EditorialDetailsComponent = (props: EditorialDetailsProps) => {
  const { editorialDetails } = props;
  return (
    <React.Fragment>
      <Link to={`/questions/${props.questionId}`}>
        <Button color="secondary" variant='contained'><ArrowBackIcon /> Back to all solutions</Button>
      </Link>

      <ReactMarkdown>
        {editorialDetails.body}
      </ReactMarkdown>
    </React.Fragment>

  )
}

export interface EditorialTabPanelProps {
  editorials: Editorial[],
  questionId: string;
  solutionId?: string;
  editorialDetails?: EditorialDetails;
}

export const EditorialTabPanel = (props: EditorialTabPanelProps) => {
  const { solutionId, questionId, editorials, editorialDetails } = props;
  return (
    <React.Fragment>
    {
      solutionId === undefined ?
      <EditorialList editorials={editorials} questionId={questionId} />
      :
      <EditorialDetailsComponent questionId={questionId} editorialDetails={editorialDetails!} />

    }
    </React.Fragment>

  )
}