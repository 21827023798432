import { observer } from "mobx-react-lite"
import { Tab, Tabs } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { EditorialTabPanel } from "./EditorialTabPanel";
import { QuestionTabPanel } from "./QuestionTabPanel";
import { QuestionDetails } from "../models/questionModel";
import { Editorial } from "../models/editorialModel";
import React from "react";
import MDEditor from '@uiw/react-md-editor';

export interface QuestionDetailTabsProps {
    questionDetails: QuestionDetails;
    editorials: Editorial[];
    solutionId?: string;
}

export const QuestionDetailTabs = observer((props: QuestionDetailTabsProps) => {

    const currentTab = props.solutionId !== undefined ? "editorials" : "questions";
    const [value, setValue] = React.useState(currentTab);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    const { questionDetails, editorials } = props;
    return (
        <TabContext value={value}>
            <Tabs value={value} onChange={handleChange} >
                <Tab label="Question" value="questions" />
                <Tab label="Editorials" value="editorials"  />
                <Tab label="Community Solutions" value="solutions"  />
                <Tab label="Create Solution" value="createSolution" />
            </Tabs>
            <TabPanel value="questions" style={{padding: 0, height: "100%"}}>
                <QuestionTabPanel questionDetails={questionDetails} />
            </TabPanel>
            <TabPanel value="editorials">
                <EditorialTabPanel editorials={editorials} questionId={"1"} />
            </TabPanel>
            <TabPanel value="solutions">
            </TabPanel>
            <TabPanel value="createSolution">
            <MDEditor
                value={"Hello World"}
            />
            </TabPanel>
        </TabContext>
    );
})
