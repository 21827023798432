import { apiClient, APIClient } from "../api/apiClient";
import { Editorial } from "../models/editorialModel";
import { observable } from "mobx";
import { makeObservable } from "mobx";


const editorials: Editorial[] = [
  {
    'id': '1001',
    'title': 'Designing using distributed systems',
    'author': 'John Doe',
    'votes': 32,
    'views': 325,
    'shortDescription': 'This solutions uses distributed systems to design Netflix',
    'tags': [
      {
        'label': 'S3',
      },
      {
        'label': 'Distributed'
      }
    ]
  },
  {
    'id': '1001',
    'title': 'Distributed Caching',
    'author': 'Mohand Das Doe',
    'votes': 12,
    'views': 9931,
    'shortDescription': 'In this blog, we will discuss how to design a website like dropbox or google drive but before we go further we want you to read the article “How to crack system design round in interviews?”. You\’ll have an idea that what this round looks like, what you are expected to do in this round and what mistakes you should avoid in front of the interviewer.    ',
    'tags': [
      {
        'label': 'Caching',
      },
      {
        'label': 'Redis'
      }
    ]
  },
  {
    'id': '1001',
    'title': 'Centralized architecture',
    'author': 'John Doe',
    'votes': 4,
    'views': 231,
    'shortDescription': 'This solutions uses a centralized architecture to design Netflix',
    'tags': [
      {
        'label': 'File system',
      },
      {
        'label': 'Caching'
      }
    ]
  }
];

export class EditorialStore {
  public editorials: Editorial[] = [];

  private client: APIClient = apiClient;
  public error = '';
  /**
   *
   */
  constructor() {
    makeObservable(this, {
      editorials: observable,
      error: observable,
    });
  }

  async loadEditorials(questionId: string) {
    try {
      await new Promise(f => setTimeout(f, 500));
      //let editorials = await this.client.listSolutions({questionId: questionId});
      this.editorials = editorials;
    } catch(Error) {
      this.error = "Error loading questions";
    }
  }

}

export const editorialStore = new EditorialStore();