import { observable } from "mobx";
import { makeObservable } from "mobx";
import { apiClient, APIClient } from "../api/apiClient";
import { Question, QuestionDetails } from "../models/questionModel";

export class QuestionStore {
  public questions: Question[] = [];
  public questionDetails: QuestionDetails = {
    'id': '1',
    'title': 'Designing Netflix',
    'hints': [],
    'tags': [{'label': 'Amazon'}],
    'popularity': 84,
    'body': 'Design netflix...',
  }
  private client: APIClient = apiClient;
  public error = '';
  /**
   *
   */
  constructor() {
    makeObservable(this, {
      questions: observable,
      error: observable,
    });
  }

  async loadQuestions() {
    try {
      let questions = await this.client.listQuestions({});
      this.questions = questions.questions;
      this.questions = this.questions.concat(questions.questions);
    } catch(Error) {
      this.error = "Error loading questions";
    }
  }

  async loadQuestion(questionId: string) {

  }

}

export const questionStore = new QuestionStore();