import Grid from '@mui/material/Grid';
import { QuestionTable } from '../components/QuestionsTable';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { questionStore } from '../stores/questionStore';

export const QuestionsPage = observer(() => {
    useEffect(() => {
        questionStore.loadQuestions();
      }, []);

    return (
      <Grid container spacing={1} style={{marginTop: '20px', height: '100%'}}>
        <Grid item sm={2}></Grid>
        <Grid item xs={12} sm={8}>
          <QuestionTable questions={questionStore.questions} />
        </Grid>
        <Grid item sm={2}></Grid>
      </Grid>
    )
  });