import Grid from '@mui/material/Grid';
import { observer } from 'mobx-react';
import { RegisterPanel } from 'src/components/RegisterPanel';

export const RegisterPage = observer(() => {
	return (
		<Grid container spacing={1} style={{marginTop: '20px', height: '100%'}}>
			<Grid item sm={3}></Grid>
			<Grid item xs={12} sm={6}>
				<RegisterPanel />
			</Grid>
			<Grid item sm={3}></Grid>
		</Grid>
	);
});
