import { apiClient, APIClient } from "../api/apiClient";
import { User } from "../models/userModel";
import { makeObservable, observable } from "mobx";


export class UserStore {
  private client: APIClient = apiClient;
	public isError: boolean = false;
	public isLoggedIn: boolean = false;
	public isLoading: boolean = false;
  constructor() {
		makeObservable(this, {
			isError: observable,
			isLoggedIn: observable,
		})
  }

	async login(username: string, password: string) {
		this.isLoading = true;
		this.isError = false;
		try {
			await this.client.login({username: username, password: password});
		} catch(err) {
			this.isError = true;
		} finally {
			if(!this.isError) this.isLoggedIn = true;
			this.isLoading = false;
		}
	}

	async registerWaitlist(email: string) {
		this.isLoading = true;
		this.isError = false;
		try {
			await this.client.registerWaitlist({email: email});
		} catch(err) {
			this.isError = true;
		} finally{
			this.isLoading = false;
		}
	}

	async register(user: User) {
		this.isLoading = true;
		this.isError = false;
		try {
			await this.client.register({user: user});
		} catch (err) {
			this.isError = true;
		} finally {
			this.isLoading = false;
		}
	}
};

export const userStore = new UserStore();
