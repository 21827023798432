import {
	Button,
	Paper,
	FormControl,
	Typography,
	TextField,
	Link,
	Grid,
	useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import React from "react";
import { observer } from "mobx-react";
import { Alert } from "@mui/material";
import { userStore } from "../stores/userStore";


export const LoginPanel = observer(() => {
	const theme = useTheme();
	const [username, setUsername] = React.useState<string>("");
	const [password, setPassword] = React.useState<string>("");
	const navigate = useNavigate();
	const login = async (e: React.MouseEvent<HTMLElement>) => {
		console.log("logging in...");
		await userStore.login(username, password);
		// TODO: redirect to redirect_url
		if (!userStore.isError) navigate("/questions");
	};
	return (
		<Paper>
			<Grid container>
				<Grid item sm={3}></Grid>
				<Grid item xs={12} sm={6}>
					<Typography variant={"h5"} sx={{ padding: theme.spacing(2) }}>
						👋 Welcome{" "}
					</Typography>
					{userStore.isError ? (
						<Alert severity="error">
							Error: Invalid username/password. Please try again.
						</Alert>
					) : (
						<React.Fragment />
					)}
					<FormControl fullWidth sx={{ margin: theme.spacing(2, 0) }}>
						<TextField
							placeholder="Username"
							variant="outlined"
							value={username}
							onChange={(e) => setUsername(e.target.value)}
							required
						/>
					</FormControl>
					<FormControl fullWidth sx={{ margin: theme.spacing(2, 0) }}>
						<TextField
							type={"password"}
							placeholder="Password"
							variant="outlined"
							onChange={(e) => setPassword(e.target.value)}
							value={password}
							required
						/>
					</FormControl>
					<FormControl fullWidth sx={{ margin: theme.spacing(2, 0) }}>
						<Button
							variant="contained"
							color="primary"
							onClick={login}
							disabled={userStore.isLoading}
						>
							Login
						</Button>
					</FormControl>
					<FormControl fullWidth>
						<Link href="/register">
							<Button variant="contained" color="secondary" fullWidth>
								Create an account instead
							</Button>
						</Link>
					</FormControl>
					<Typography
						sx={{
							textAlign: "center",
							color: theme.palette.text.primary,
							padding: theme.spacing(2),
						}}
					>
						<Link href="/forgot">Forgot your password?</Link>
					</Typography>
				</Grid>
			</Grid>
		</Paper>
	);
});
