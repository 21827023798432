import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';

import Rating from '@mui/material/Rating';
import { Difficulty, Question, Tag } from '../models/questionModel';
import { Link } from 'react-router-dom';


interface DifficultyLabelProps {
    difficulty: Difficulty
}
const DifficultyLabel = function(props: DifficultyLabelProps) {
    switch (props.difficulty) {
        case Difficulty.EASY:
            return <Chip label={props.difficulty} color="success" sx={{width: 80}}></Chip>
        case Difficulty.MEDIUM:
            return <Chip label={props.difficulty} color="warning" sx={{width: 80}}></Chip>
        case Difficulty.HARD:
            return <Chip label={props.difficulty} color="error" sx={{width: 80}}></Chip>
        case Difficulty.HARDEST:
            return <Chip label={props.difficulty} color="error" sx={{width: 80}}></Chip>
        default:
            return <Chip />
    }
}

interface TagDisplayProps {
    tags: Tag[];
}
const TagDisplay = function(props: TagDisplayProps) {

    const res =  props.tags.map((tag) => {
        return <Chip key={tag.label} label={tag.label} />
    });
    return <div>{res}</div>;
}

export interface QuestionTableProps {
  questions: Question[];
}

export const QuestionTable = (props: QuestionTableProps) => {

  return (
    <TableContainer component={Paper} style={{height: "100%"}} >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Question</TableCell>
            <TableCell align="right">Difficulty</TableCell>
            <TableCell align="right">Popularity</TableCell>
            <TableCell align="right">Tags</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.questions.map((question: Question) => (
            <TableRow
              key={question.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Link to="/questions/1">
                  {question.title}
                </Link>
              </TableCell>
              <TableCell align="right"><DifficultyLabel difficulty={question.difficulty} /></TableCell>
              <TableCell align="right"><Rating value={question.popularity/20}  /></TableCell>
              <TableCell align="right"><TagDisplay tags={question.tags}></TagDisplay></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};