export enum Difficulty {
  EASY = "Easy",
  MEDIUM = "Medium",
  HARD = "Hard",
  HARDEST = "Hardest",
}

export interface Tag {
  label: string;
}


export interface Question {
  id: string;
  title: string;
  difficulty: Difficulty;
  popularity: number;
  tags: Tag[];
}

export interface Hint {
  body: string;
}

export interface QuestionDetails {
  id: string;
  title: string;
  body: string;
  popularity: number;
  tags: Tag[];
  hints: Hint[];
}

export interface Solution {
  id: string;
  questionId: string;
  title: string;
  author: string;
}

export interface SolutionDetails {
  id: string;
  questionId: string;
  title: string;
  body: string;
}


export interface ListQuestionsRequest {}

export interface ListQuestionsResponse {
	questions: Question[];
}

export interface GetQuestionRequest {
	id: string;
}

export interface GetQuestionResponse {
	question: QuestionDetails;
}
