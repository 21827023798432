import {
	BrowserRouter as Router,
	Routes,
	Route,
} from "react-router-dom";

import "./App.css";

import { Header } from "./components/Header";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { CssBaseline } from "@material-ui/core";
import { QuestionsPage } from "./pages/QuestionsPage";
import { QuestionDetailsPage } from "./pages/QuestionDetailsPage";
import { LoginPage } from "./pages/LoginPage";
import { deepPurple } from "@mui/material/colors";
import { RegisterPage } from "./pages/RegisterPage";
import { LandingPage } from './pages/LandingPage';

const theme = createTheme({
	palette: {
		primary: deepPurple,
		secondary: {
			main: "#FFC052",
		},
	},
});

export const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Header />

			<Router>
				<Routes>
					<Route path="/" element={<LandingPage />} />
					<Route path="/questions" element={<QuestionsPage />} />
					<Route
						path="/questions/:questionId/solutions/:solutionId"
						element={<QuestionDetailsPage />}
					/>
					<Route
						path="/questions/:questionId"
						element={<QuestionDetailsPage />}
					/>
					<Route path="/register" element={<RegisterPage />} />
					<Route path="/login" element={<LoginPage />} />
				</Routes>
			</Router>
		</ThemeProvider>
	);
};

export default App;
